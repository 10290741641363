.TextButtonImageMainCtBackground{
    background-color: #f0f0f0;
}

.TextButtonImageMainCt{
    padding:30px 10px 60px 10px;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
}

.displayImageRules{
    max-width: 100%;
    height: auto;
    /* box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.TextButtonImageTxtCt{
    padding:140px 20px 60px 10px;
}

.TextButtonImageTxtCtHeading{
    font-size: 36px;
    font-weight: 750;
}

.TextButtonImageTxtCtSubHeading{
    padding-top: 20px;
    font-size: 18px;
    line-height: 1.5;
    color:rgb(89, 89, 89)
}

.TextButtonImageTxtCtButton{
    padding-top: 20px;
}

.TextButtonImageTxtCtButtonDesign{
    color: #fff !important;
    font-size: 0.9rem !important;
    margin-top:3px !important;
    padding-top:12px !important;
    padding-bottom:12px !important;
    margin-right:20px !important;
}

.typed-txt{
    color: #42ba96 !important
}

.TextButtonImageTxtCtHeading1{
    display: block;
}

.TextButtonImageTxtCtButtonDisabledTxt1{
    font-size: 12px;
    padding-top: 10px;
    color: rgb(89, 89, 89);
}

@media (max-width: 1070px) { 
    .TextButtonImageTxtCt{
        padding:80px 20px 60px 10px;
    }
}

@media (max-width: 930px) { 
    .TextButtonImageTxtCt{
        padding:40px 20px 40px 10px;
    }
}

@media (max-width: 899px) { 
    .displayImageRulesPrimary{
        display: none;
    }
    
    .TextButtonImageTxtCt{
        padding:40px 10px 40px 10px;
    }

    .TextButtonImageTxtCtHeading{
        font-size: 32px;
    }

    .TextButtonImageTxtCtButtonDesign{
       width: 100%;
    }

    .TextButtonImageTxtCtHeading1{
        display:inline;
    }

}
