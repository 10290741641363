.footer-ct{
    background-color: #000;
    padding-bottom: 6rem!important;
    padding-top: 6rem!important;
    color:#fff;
}

.footer-content-ct{
    max-width:1200px !important;
    margin-left:auto;
    margin-right:auto;
}

.footer-logo{
    width: 140px;
    margin-bottom: .5rem!important;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}

.footer-social-icons-row{
    margin-top:10px !important;
    margin-bottom: 10px !important;
}

.footer-social-icons-links{
    text-decoration: none !important;
    color:inherit !important;
    margin-left:5px !important;
    margin-right:5px !important;
}

.footer-social-icons-links:hover{
    cursor:pointer;
}

.copyright-ct{
    margin-top:0px !important;
    margin-bottom:10px !important;
}

.copyright-txt{
    font-size: 12px;
    color: #b3b3b3;
}

@media (max-width: 899px) { 
    .footer-content-ct{
       padding-left:10px;
    }
}