.TextButtonImageMainCt2{
    background-color: #fff;
    padding:120px 10px 60px 10px;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
}

.ImgCustomStyle{
    max-width: 400px !important;
    max-height: 500px !important;
    float: right;
    margin-right:20px;
}

.TextButtonImageTxtCtRight{
    padding:160px 20px 60px 10px;
}

.TextButtonImageTxtCtHeadingRight{
    font-size: 32px;
    font-weight: 650;
}

.TextButtonImageTxtCtSubHeadingRight{
    padding-top: 20px;
    font-size: 18px;
    line-height: 1.5;
    color:rgb(89, 89, 89)
}

@media (max-width: 1070px) { 
    .TextButtonImageTxtCtRight{
        padding:80px 20px 60px 10px;
    }
}

@media (max-width: 930px) { 
    .TextButtonImageTxtCtRight{
        padding:40px 20px 40px 10px;
    }
}

@media (max-width: 899px) { 
    .TextButtonImageMainCt2{
        padding:120px 10px 60px 10px;
    }    

    .TextButtonImageTxtCtRight{
        padding:40px 10px 40px 10px;
    }

    .TextButtonImageTxtCtHeadingRight{
        font-size: 28px;
    }

    .TextButtonImageTxtCtSubHeadingRight{
        padding-top: 10px;
        font-size: 16px;
    }

    .ImgCustomStyle{
        max-width: 480px !important;
        max-height: 480px !important;
        float: none;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .todoProjectsMobileImgCt2{
        display: none;
    }

}