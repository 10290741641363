.head-non-auth-ct{
    background-color: #f0f0f0;
    border-bottom: none;
    padding: .75rem 0!important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transition-property: background-color,color;
    transition-property: background-color,color;
    z-index: 1030;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.topNavMainCt{
    max-width: 1200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.inoook-head-logo{
    width: 134px;
    padding-top:4px;
    vertical-align: middle;
    border-style: none;
    margin-left:2px !important;
}

.head-right-button{
    color: #fff !important;
    float:right;
    font-size: 0.9rem !important;
    margin-top:3px !important;
    padding-top:8px !important;
    padding-bottom:8px !important;
    margin-right:20px !important;
}


@media (max-width: 990px) { 
    .inoook-head-logo{
        width: 132px;
        vertical-align: middle;
        border-style: none;
        margin-left:10px !important;
    }
 }