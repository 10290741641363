.landingPageCenterCt4Main{
    margin-top:100px;
    text-align: center;
    padding: 100px 10px 100px 10px;
    color: #fff;
    background-color: #90011e !important;
}

.LandingPageCenterCt4ButtonDesign{
    background-color: #fff !important;
    color: #90011e !important;
    border: none !important;
    font-size: 0.9rem !important;
    margin-top:30px !important;
    padding-top:14px !important;
    padding-bottom:14px !important;
}

.LandingPageCenterCt4ButtonDesign:hover{
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.LandingPageCenterCt4DisabledButtonDesign{
    font-size: 0.9rem !important;
    margin-top:30px !important;
    padding-top:14px !important;
    padding-bottom:14px !important;
    color: #fff !important;
    border: 1px solid #fff !important;
}

.TextButtonImageTxtCtHeadingCt4{
    font-size: 32px;
    font-weight: 750;
}

.TextButtonImageTxtCtHeadingCt4Icon{
    font-size: 84px !important;
    padding-bottom: 10px;
}

.TextButtonImageTxtCtButtonCt4{

}

.TextButtonImageTxtCtButtonDisabledTxt1Ct4{
    font-size: 12px;
    padding-top: 10px;
    color: rgb(255, 255, 255);
}


@media (max-width: 899px) { 
    .landingPageCenterCt4Main{
        margin-top:40px;
        padding: 80px 10px 80px 10px;
    }

    .TextButtonImageTxtCtHeadingCt4{
        font-size: 26px;
    }

    .TextButtonImageTxtCtHeadingCt4Icon{
        font-size: 64px !important;
        padding-bottom: 10px;
    }
}