
.TextButtonImageMainCt3{
    background-color: #fff;
    padding:120px 10px 60px 10px;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
}

.displayImageRulesMobile{
    display:none;
}

@media (max-width: 899px) { 
    .displayImageRulesMobile{
        display:block;
    }

    .TextButtonImageMainCt3{
        padding:40px 10px 40px 10px;
    }  
}